// Copyright © Aptos
// SPDX-License-Identifier: Apache-2.0

import { Aptos, AptosConfig, Network } from "@aptos-labs/ts-sdk";

export const LocalStorageKeys = {
	keylessAccounts: "@aptos-connect/keyless-accounts",
};

export const devnetClient = new Aptos(
	new AptosConfig({ network: Network.MAINNET })
);

/// FIXME: Put your client id here
export const GOOGLE_CLIENT_ID =
	"805110208964-65mrn0ucqoji1a7u9ccrpl62vietpdq1.apps.googleusercontent.com";
	// "805110208964-s2u9jpu6hrhaib1u5chtg2ob069nnhi8.apps.googleusercontent.com"
