// AptosWalletConnect.tsx
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useWallet, WalletName } from "@aptos-labs/wallet-adapter-react";
import logoapp from "../../../../assets/images/teleImage/iconSlime.png";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import { devnetClient, GOOGLE_CLIENT_ID } from "../../../../core/constants";
import useEphemeralKeyPair from "../../../../core/useEphemeralKeyPair";
import { requestUpdateProfile } from "../../../../services/accountApiService";
import { collapseAddress } from "../../../../core/utils";
import { useNavigate } from "react-router-dom";
import { useKeylessAccounts } from "../../../../core/useKeylessAccounts";
import {
	aptos,
	MARKET_PLACE_CONTRACT,
	MODULE_ADDRESS,
} from "../../../../aptosConfig";
import { jwtDecode } from "jwt-decode";
import { EphemeralKeyPair } from "@aptos-labs/ts-sdk";
import HomePage from "../../../../HomePage";
import { getLocalKeylessAccount } from "../../../../utils/aptos/aptosStorage";
import { apiRegisterAccountWithAddress } from "../../../../services/claimApiService";

type AptosWalletConnectType = {
	onClose: () => void;
	fncGetData: () => void;
};
export const getAptosClient = getLocalKeylessAccount();
export const getAllListingObjectAddresses = async () => {
	const allListings: [string[]] = await aptos.view({
		payload: {
			function: `${MODULE_ADDRESS}::${MARKET_PLACE_CONTRACT}::get_seller_listings`,
			typeArguments: [],
			functionArguments: [
				"0x5b53af06b07be246c97b030e2665617cb3b559a51226c087663b98d8c405756",
			],
		},
	});
	console.log("all listings", allListings);
	return allListings[0];
};
export default function AptosWalletConnect({
	onClose,
	fncGetData,
}: AptosWalletConnectType) {
	const [isLoading, setLoading] = useState(true);
	const [isLoaded, setLoaded] = useState(false);

	const ephemeralKeyPair1 = EphemeralKeyPair.generate();
	const ephemeralKeyPair = useEphemeralKeyPair();

	const redirectUrl = new URL("https://accounts.google.com/o/oauth2/v2/auth");

	const searchParams = new URLSearchParams({
		client_id: GOOGLE_CLIENT_ID,
		redirect_uri: `${window.location.origin}/callback`,
		response_type: "id_token",
		scope: "openid email profile",
		nonce: ephemeralKeyPair.nonce,
	});
	const navigate = useNavigate();

	const parseJWTFromURL = (url: string): string | null => {
		const urlObject = new URL(url);
		const fragment = urlObject.hash.substring(1);
		const params = new URLSearchParams(fragment);
		return params.get("id_token");
	};

	const { activeAccount, disconnectKeylessAccount } = useKeylessAccounts();

	console.log(activeAccount?.accountAddress.toString());

	const updateAccountWallet = async () => {
		await requestUpdateProfile(
			activeAccount?.accountAddress.toString(),
			"Aptos"
		);
		onClose();
		// navigate("/");
	};

	const handleLoginWithAddress = async () => {
		try {
			const responseRegisterAccount: any =
				await apiRegisterAccountWithAddress({
					WalletAddress: activeAccount?.accountAddress.toString(),
					WalletType: "Aptos",
				});
			if (responseRegisterAccount?.success) {
				localStorage.setItem(
					"_gameAccessToken",
					responseRegisterAccount?.data?.access_token
				);
				localStorage.setItem(
					"_WalletAddress",
					JSON.stringify(activeAccount?.accountAddress.toString())
				);
				localStorage.setItem(
					"info_data",
					JSON.stringify(responseRegisterAccount?.data)
				);
				await fncGetData();

				navigate("/");
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		const checkLogin = localStorage.getItem("_gameAccessToken");
		if (activeAccount && checkLogin) {
			updateAccountWallet();
		}
	}, [activeAccount, navigate]);

	useEffect(() => {
		const checkLogin = localStorage.getItem("_gameAccessToken");
		if (!checkLogin) {
			handleLoginWithAddress();
		}
		if (activeAccount) {
			onClose();
		}
	}, [activeAccount]);

	redirectUrl.search = searchParams.toString();

	useEffect(() => {
		// getAllListingObjectAddresses();
	}, []);

	useEffect(() => {
		localStorage.setItem("nonceKeyless", ephemeralKeyPair.nonce);
	}, []);

	const sendTransaction = async () => {
		const getAptosClient = getLocalKeylessAccount();
		const accountCoinsData = await devnetClient.getAccountCoinsData({
			accountAddress: getAptosClient!.accountAddress.toString(),
		});
		const transaction = await devnetClient.transferCoinTransaction({
			sender: getAptosClient!.accountAddress,
			recipient:
				"0xe012822f44b2765789a8b50cded4d71874a264d55b28eb61cfbe38abb1c6dd8b",
			amount: 100,
		});

		const committedTxn = await devnetClient.signAndSubmitTransaction({
			signer: getAptosClient!,
			transaction,
		});
		console.log({ committedTxn });
		console.log({ accountCoinsData });
	};

	const handleTelegramResponse = (response: any) => {
		console.log(response);
	};

	const YourComponent = () => {
		return (
			<div className={styles.container}>
				<div
					style={{
						display: "flex",
						alignItems: "center",
						columnGap: "10px",
						fontFamily: "Poppins",
					}}
				>
					<img src={logoapp} alt="Logo" className={styles.logo} />
					<h1 className={styles.title}>Slime Revolution</h1>
				</div>
				<p className={styles.subtitle}>
					{`Slime Revolution is The first social NFT Game build on APTOS.
Build Island, Claim Airdrop & Collect NFT for free.
Play for free now 👇👇👇`}
				</p>
				<a
					href={redirectUrl.toString()}
					className={styles.login_with_google_btn}
				>
					Sign in with Google
				</a>
				{activeAccount && (
					<p>
						{collapseAddress(
							activeAccount?.accountAddress.toString()
						)}
					</p>
				)}
				<div className={styles.aboutStyle}>
					<a
						href="https://t.me/SlimeRoyale"
						target="_blank"
						style={{
							color: "black",
							fontFamily: "Poppins",
							fontSize: "12px",
							display: "flex",
							alignItems: "center",
							columnGap: "4px",
						}}
						rel="noopener noreferrer"
					>
						<FaTelegramPlane size={16} color="black" />
						{` Join Telegram`}
					</a>
					<a
						href="https://x.com/SlimeRoyale/"
						target="_blank"
						style={{
							color: "black",
							fontFamily: "Poppins",
							fontSize: "12px",
							display: "flex",
							alignItems: "center",
							columnGap: "4px",
						}}
						rel="noopener noreferrer"
					>
						<FaTwitter size={16} color="black" />
						{` Follow us on Twitter`}
					</a>
					{/* <a
						style={{
							color: "black",
							fontFamily: "Poppins",
							fontSize: "12px",
							display: "flex",
							alignItems: "center",
							columnGap: "4px",
						}}
						href="https://test-dapp.slimeroyale.com/home"
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaBook size={16} color="black" />
						{` About Slime Revolution`}
					</a> */}
				</div>
			</div>
		);
	};

	return (
		<div className={`${styles.loading__game}`}>
			<div className={styles.loadingContainer}>
				<YourComponent />
			</div>
		</div>
	);
}
